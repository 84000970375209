import { useMutation } from "@apollo/client"
import { FC, useContext, useEffect, useState } from "react"
import Select from "react-select"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import { CreateTempBillingReportModalProps } from "../../interfaces"
import DragDropFile from "../../Pages/candidate/candidate-components/DragDropFile"
import { bucketFolders, TEMP_BILLING_IMPORTS_OPTIONS } from "../../utils/constant"
import { updateFileName, uploadFile, uploadImage } from "../../utils/helper"
import CustomButton from "../layout/CustomButton"
import { CREATE_TEMP_BILLING_IMPORT } from "./gql"
import { AppContext } from "../../context"
import { customSelectDropDownMin } from "../styled/customSelect"


const CreateTempBillingReportModal: FC<CreateTempBillingReportModalProps> = ({ isOpen, toggle, refetch }) => {
  const { theme } = useContext(AppContext)
  const [uploadingFile, setUploadingFile] = useState<boolean>(false)
  const [file, setFile] = useState<File | null>()
  const [fileName, setFileName] = useState<string>()
  const [monthYr, setMonthYr] = useState<string>(new Date().toISOString().substring(0, 7))
  const [reportType, setReportType] = useState<string>()

  const [createRecord, { loading }] = useMutation(CREATE_TEMP_BILLING_IMPORT, {
    onCompleted: () => {
      toast.success("added successfully")
      refetch()
      toggle()
    },
    onError: () => toast.error("something went wrong")
  })

  const onAdd = async () => {
    if (fileName && reportType && file) {
      setUploadingFile(true)
      const { source: fileLink } = await uploadFile(
        new File([file!], updateFileName(file!.name), { type: file!.type }),
        bucketFolders.tempBilling,
        false, "attachment"
      )
      setUploadingFile(false)
      await createRecord({
        variables: {
          input: {
            fileName, reportType, fileLink, monthYr
          }
        }
      })
    }
  }
  useEffect(() => {
    setFile(null)
    setFileName('')
    setReportType('')
  }, [isOpen])

  return (
    <Modal Modal isOpen={isOpen} toggle={toggle} className="revampDialog revampDialogWidth">
      <ModalHeader>
        Temp Billing Partner Upload
      </ModalHeader>
      <ModalBody className="p-4">
        <Col>
          <Row>
            <Col sm="6">
              <p className="font-weight-bold">File Type <span className="text-danger">*</span></p>
              <Select
                placeholder="Source"
                isSearchable={false}
                options={TEMP_BILLING_IMPORTS_OPTIONS}
                onChange={(item) => {
                  setReportType(item?.value)
                }}
                styles={{ ...customSelectDropDownMin(theme, 0) }}
              />
            </Col>

            <Col sm="6">
              <p className="font-weight-bold">Month/Yr <span className="text-danger">*</span></p>
              <Input
                type="month"
                value={monthYr}
                onChange={(item) => {
                  setMonthYr(item?.target?.value)
                }}
                max={new Date().toISOString().substring(0, 7)}
              />
            </Col>
          </Row>
        </Col>
        <Col className="mt-3">
          <p className="font-weight-bold">File <span className="text-danger">*</span></p>
          {fileName && <Label>File Name: {fileName}</Label>}
          <DragDropFile onUpload={(file: File) => {
            setFile(file)
            setFileName(file.name)
          }} />
        </Col>
        <div className="mt-5 d-flex w-40 align-self ml-auto">
          <CustomButton
            className="ml-auto" disabled={(uploadingFile || loading) || !(fileName && reportType && file && monthYr)} loading={uploadingFile || loading}
            buttonClick={onAdd} buttonText="Upload File"
          />
        </div>
      </ModalBody>
    </Modal>
  )
}
export default CreateTempBillingReportModal