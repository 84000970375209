import { FC, useState } from "react";
import { Button, Spinner } from "reactstrap";
import DOWNLOAD_ICON from "../assets/images/download-icon.svg";
import { DownloadFileBtnProps } from "../interfaces";
import { downloadSignedUrlFile } from "../utils/commonFn";

const DownloadButton: FC<DownloadFileBtnProps> = ({ url, nickName, fileType }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Button
      onClick={() => {
        setLoading(true);
        downloadSignedUrlFile(url, fileType ? `${nickName}.${fileType}` : `${nickName}`, setLoading)
      }}
      className="bg-transparent border-0 p-0 mr-3"
    >
      {loading && <Spinner size="sm" style={{ color: "#eaa827" }} className="m-0" />}
      {!loading && <img className="m-0" src={DOWNLOAD_ICON} alt="download" />}
    </Button>
  );
};

export default DownloadButton