import { FC, useContext, useEffect, useState } from 'react';
// packages block
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { Button, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';
// components block
import CustomButton from '../../../components/layout/CustomButton';
// interfaces, graphql, constants block
import {
  AREA_WITHIN_RADIUS,
  CITY,
  CLEAR,
  COUNTRY,
  CUSTOM_SELECT_STYLE,
  FILTERS,
  RADIUS_OPTIONS,
  STATE,
  ZIP,
} from '../../../constants';
import { AppContext } from '../../../context';
import { CandidateSearchFilter } from '../../../interfaces';
import { countries } from '../../../utils/constant';
import SEARCH_IMG from '../../../assets/images/search.svg';
import { customSelectStyleNew } from '../../../components/styled/customSelect';


const CandidateSearchFilters: FC<CandidateSearchFilter> = ({
  selectedState,
  city,
  zipCode,
  setCity,
  setState,
  setZipCode,
  radius,
  setRadius,
  getCandidatesList,
  clearFilters,
  aggregateResult,
  handleSkills,
  handleSkillsFromSearch,
  setCurrentPage,
  setStateList,
  stateList,
  selectedCountry,
  setSelectedCountry,
  searchCall,
  setSearchCall,
  excludeSkills,
  setExcludeSkills,
  handleSearchEnterDown,
  handleSearchEnterUp
}, props) => {
  // window.addEventListener('keypress', enterClicked);
  const [advanceSearch, setAdvanceSearch] = useState<{ label: string, value: string, color: string }[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [aggregationResult, setAggregationResult] = useState<Array<any>>([]);
  const [seeMoreBtn, setSeeMoreBtn] = useState<boolean>(false);
  const { userRoles, skillsESAggs } = useContext(AppContext);
  const [facets, setFacets] = useState({});

  const handleCheckboxChange = (value) => {
    console.log("changing ", value)
    setFacets((prevFacets) => ({
      ...prevFacets,
      [value]: !prevFacets[value],
    }));
  };
  const customSelectStyle = (theme) => ({
    control: (styles, provided) => ({
      ...styles,
      background: theme === "light" ? provided.background : "#282828",
      borderColor: theme === "light" ? "#ffffff" : "#ffffff",
      color: theme === "light" ? provided.background : "#d9d9d9",
      fontSize: 12,
      "&:hover": {
        borderColor: theme === "light" ? "#ffffff" : "#ffffff",
        color: theme === "light" ? provided.background : "#d9d9d9",
      },
      boxShadow: provided.isFocused && `${theme === "light" ? "#ffffff" : "#ffffff"}`
    }),
    menu: (provided) => ({
      ...provided,
      display: 'none'
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 12,
      background: theme === "light" ? provided.background : "#363636",
      "&:hover": {
        background: theme === "light" ? provided.background : "#474444",
        color: theme === "light" ? provided.background : "#d9d9d9",
      },
    }),
    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
      width: '70.87%',
    }),
    placeholder: (provided) => ({
      ...provided,
      opacity: 0.8
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    indicatorsContainer: () => ({
      display: 'none'
    }),
    multiValue: (styles, { data }) => ({
      ...styles,
      backgroundColor: data.color === 'include' ? '#e0ffe0' : '#ffe0e0',
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color === 'include' ? '#008000' : '#ff0000',
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color === 'include' ? '#008000' : '#ff0000',
      "&:hover": {
        background: data.color === 'include' ? '#cbf8cb' : '#ffbbbb',
        color: data.color === 'include' ? '#008000' : '#ff0000',
      },
    }),
  });

  useEffect(() => {
    return () => {
      window.removeEventListener('keypress', enterClicked);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { theme } = useContext(AppContext);

  function enterClicked(event: any) {
    if (event.key === 'Enter') {
      event.preventDefault();
      getCandidatesList();
    }
  }

  useEffect(() => {
    if (!city && !zipCode) {
      setRadius('')
    }
  }, [city, zipCode])

  useEffect(() => {
    if (aggregateResult?.length) {
      let splitResult = aggregateResult.slice(0, 10);
      setAggregationResult(splitResult);
      setFacets(aggregateResult?.reduce((acc, { key }) => ({ ...acc, [key]: false }), {}))
      skillsESAggs?.map((item) => handleCheckboxChange(item))

    } else {
      setAggregationResult([]);
    }
  }, [aggregateResult]);

  const seeMore = () => {
    if (aggregateResult?.length > 10 && aggregationResult?.length <= 10) {
      let total = aggregateResult?.length;
      let splitResult = aggregateResult.slice(10, total + 1);
      setAggregationResult([...aggregationResult, ...splitResult]);
    } else {
      let splitResult = aggregateResult.slice(0, 10);
      setAggregationResult(splitResult);
    }
  };

  return (
    <div className="canidate-filters-search p-3">
      <h5 className="font-weight-bold text-dark">{FILTERS}</h5>


      <div className="filters filters-by-state pt-3">
        <div className="d-flex align-items-center mb-2 justify-content-between">
          <Label className="mb-0">{COUNTRY}</Label>
          {/* <button
            className="btn p-0"
            onClick={() => {
              setState({ label: '', value: '' });
              setRadius('');
            }}
          >
            {CLEAR}
          </button> */}
        </div>

        <Select
          name="country"
          options={countries}
          onChange={(e) => {
            setSelectedCountry(e?.value)
            setCurrentPage(1);
          }}
          defaultValue={{ label: 'US', value: 'US', phonecode: '+1' }}
          styles={{ ...customSelectStyleNew(theme) }}
        />
      </div>

      <div className="filters filters-by-state pt-3">
        <div className="d-flex align-items-center mb-2 justify-content-between">
          <Label className="mb-0">{STATE}</Label>
          <button
            className="btn p-0"
            onClick={() => {
              setState({ label: '', value: '' });
              setRadius('');
            }}
          >
            {CLEAR}
          </button>
        </div>

        <Select
          name="state"
          isDisabled={!['US', null, undefined, ''].includes(selectedCountry)}
          value={selectedState.value ? selectedState : null}
          options={stateList}
          onChange={(e) => {
            setState(e);
            setCurrentPage(1);
          }}
          styles={{ ...customSelectStyleNew(theme) }}
        />
      </div>

      <div className="filters filters-by-city pt-3">
        <div className="d-flex align-items-center mb-2 justify-content-between">
          <Label className="mb-0">{CITY}</Label>
          <button className="btn p-0" onClick={() => setCity('')}>
            {CLEAR}
          </button>
        </div>

        <Input
          type="text"
          name="city"
          value={city}
          placeholder="City..."
          styles={CUSTOM_SELECT_STYLE}
          onChange={(e) => setCity(e.target.value)}
          onKeyDown={handleSearchEnterDown}
          onKeyUp={handleSearchEnterUp}
        />
      </div>

      <div className="filters filters-by-zip pt-3">
        <div className="d-flex align-items-center mb-2 justify-content-between">
          <Label className="mb-0">{ZIP}</Label>

          <button className="btn p-0" onClick={() => setZipCode('')}>
            {CLEAR}
          </button>
        </div>

        <Input
          type="text"
          name="zipcode"
          value={zipCode}
          placeholder="Zip code or postal code..."
          styles={CUSTOM_SELECT_STYLE}
          onChange={(e) => setZipCode(e.target.value)}
        />
      </div>

      <div className="filters filters-by-zip pt-3">
        <div className="d-flex align-items-center mb-2 justify-content-between">
          <Label className="mb-0">{AREA_WITHIN_RADIUS}</Label>

          <button className="btn p-0 dark-theme-text" onClick={() => setRadius('')}>
            {CLEAR}
          </button>
        </div>

        <Select
          name="areaWithRadius"
          options={RADIUS_OPTIONS(selectedCountry)}
          value={!!radius.value ? radius : null}
          isDisabled={!city && !zipCode && true}
          onChange={(e) => setRadius(e)}
          styles={{ ...customSelectStyleNew(theme) }}
        />
      </div>

      {(userRoles?.includes('ADMIN') || userRoles?.includes('EXECUTIVE')) && (
        <div className="my-4">
          <div className="d-flex align-items-center mb-2 justify-content-between">
            <Label className="mb-2">Filter By Skills</Label>
          </div>
          <div className="search-filter d-block searchFilterResponsive py-3">
            <div className="serach-for-candidate mr-2">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <img src={SEARCH_IMG} alt="search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Creatable
                  isClearable={false}
                  filterOption={(option, inputValue) => {
                    // Custom filtering logic
                    // Example: Only show options that include the input value (case-insensitive)
                    const searchTerm = inputValue.toLowerCase();
                    console.log("option, inputValue ", option, inputValue)
                    return option.label.toLowerCase().includes(searchTerm);
                  }}
                  inputValue={inputValue}
                  onInputChange={(value, actionMeta) => {
                    if (actionMeta.action === 'input-change') {
                      setInputValue(value);
                    }
                  }}
                  value={advanceSearch}
                  multiValueRemove={true}
                  isMulti
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      setAdvanceSearch([...advanceSearch, { label: inputValue, value: inputValue, color: 'include' }])
                      handleSkillsFromSearch(inputValue)
                      setInputValue('')
                      console.log("advanceSearch ", advanceSearch)
                      // setSearchCall(!searchCall)
                    }
                  }}
                  placeholder="Skills..."
                  onChange={(val, actionMeta) => {
                    setAdvanceSearch(val.map((item) => item))
                    setSearchCall(!searchCall)
                    if (actionMeta.action === 'remove-value' || actionMeta.action === 'pop-value') {
                      handleCheckboxChange(actionMeta.removedValue?.value)
                      handleSkillsFromSearch(actionMeta.removedValue?.value, true, actionMeta.removedValue?.color === 'exclude')
                    }
                  }}
                  styles={{ ...customSelectStyle(theme) }}
                />

                <InputGroupAddon addonType="prepend">
                  <button disabled={!inputValue} onClick={() => {
                    setAdvanceSearch([...advanceSearch, { label: inputValue, value: inputValue, color: 'include' }])
                    setInputValue('')
                    handleSkillsFromSearch(inputValue)
                  }} className='bg-transparent border-0 px-1'>
                    +                    </button>
                  <button disabled={!inputValue} onClick={() => {
                    setAdvanceSearch([...advanceSearch, { label: inputValue, value: inputValue, color: 'exclude' }])
                    setExcludeSkills([...excludeSkills, inputValue])
                    setInputValue('')
                    setSearchCall(!searchCall)
                  }} className='bg-transparent border-0 px-1'>
                    -                    </button>
                </InputGroupAddon>
              </InputGroup>
            </div>
          </div>
          <div>
            {!!aggregationResult?.length && aggregationResult?.map((item) => (
              <div className=" ml-4 mt-2">
                <Input
                  type="checkbox"
                  className="checkboxStyle"
                  value={item?.key}
                  checked={facets[item?.key] || false}
                  onChange={(e) => {
                    console.log("before change :: ", e.target.checked)
                    // handleCheckboxChange(item?.key)
                    handleSkills(e)
                    if (e.target.checked) {
                      setAdvanceSearch([...advanceSearch, { label: item?.key, value: item?.key, color: 'include' }])
                    }
                    if (!e.target.checked) {
                      setAdvanceSearch(advanceSearch?.filter((val) => val.value !== item.key))
                    }
                  }}
                />
                <div style={{ fontSize: '12px', paddingTop: '3px' }}>
                  {item?.key} ({item?.doc_count})
                </div>
              </div>
            ))}
            {!!aggregationResult?.length && aggregateResult?.length > 10 && (
              <Button
                className="border-0 bg-transparent rounded text-primary pl-1"
                style={{ fontSize: '14px' }}
                onClick={() => {
                  seeMore();
                  setSeeMoreBtn(!seeMoreBtn);
                }}
              >
                {seeMoreBtn ? 'See Less...' : 'See More..'}
              </Button>
            )}
          </div>
        </div>
      )}

      <div className='mt-4 d-flex align-items-center justify-content-around flex-wrap gap-10'>
        <CustomButton
          buttonText={'Search results'}
          buttonColor="primary"
          buttonType="button"
          buttonClick={() => {
            setSearchCall(!searchCall);
            setCurrentPage(1)
          }}
        />

        <CustomButton
          buttonText={'Clear'}
          buttonColor={''}
          buttonType="button"
          buttonClick={() => {
            clearFilters();
            setAdvanceSearch([])
            setAggregationResult([]);
          }}
          className="graydark-button py-2"
        />
      </div>
    </div>
  );
};

export default CandidateSearchFilters;
