import { Pagination } from "reactstrap"
import { ITempBillingImportsData } from "../../interfaces"
import { formatDateUSWithMoment } from "../../utils/commonFn"
import { COLUMN_INTERFACE_TYPES_FOR_TEMP_BILLING_IMPORTS, PAGE_LIMIT, TEMP_BILLING_IMPORTS_HEADER } from "../../utils/constant"
import TableComponent from "../layout/TableComponent"
import { useCallback, useEffect, useState } from "react"
import InputField from "../layout/InputField"
import { useLazyQuery } from "@apollo/client"
import { LISTING_TEMP_DATA_IMPORTS } from "./gql"
import NoDataFound from "../layout/NoDataFound"
import Loader from "../layout/Loader"
import CustomButton from "../layout/CustomButton"
import CreateTempBillingReportModal from "./CreateModal"
import { useForm } from "react-hook-form"
import moment from "moment"
import { TEMP_BILLING_IMPORT } from "../../constants"
import DownloadButton from "../DownloadButton"

let time = 0
const AllTempBillingReports = () => {
  const defaultColumnFilters: COLUMN_INTERFACE_TYPES_FOR_TEMP_BILLING_IMPORTS = {
    monthYrSearch: '',
    sorting: {
      columnName: '',
      columnValue: ''
    }
  };
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filtering, setFiltering] = useState<boolean>(false)
  const [columnFilters, setColumnFilters] =
    useState<COLUMN_INTERFACE_TYPES_FOR_TEMP_BILLING_IMPORTS>(defaultColumnFilters);

  const [listTempDataImports, { data: listData, loading: listLoading, refetch }] = useLazyQuery<{ dataImportTempBillings: { dataImports: ITempBillingImportsData[], count: number } }>(LISTING_TEMP_DATA_IMPORTS);
  const { register, setValue } = useForm()

  const changeFiled = (fieldName, value) => {
    if (fieldName?.endsWith('Sort')) {
      setFiltering(false)
      const columnName = fieldName.replace('Sort', '');
      setColumnFilters((prevFilters) => {
        const { sorting, ...otherFilters } = prevFilters;
        return {
          ...otherFilters,
          sorting: {
            columnName: columnName,
            columnValue: value ? 'ASC' : 'DESC',
          },
        };
      });
    } else {
      setFiltering(true)
      setColumnFilters((prevFilters) => ({
        ...prevFilters,
        [fieldName]: value,
      }));
    }
    console.log("columnFilter :: ", columnFilters)
  };

  const mapCSVListData = ({
    fileLink, fileName, uploadedBy: { fullName }, monthYr, createdAt, reportType
  }: ITempBillingImportsData) => {
    return (
      <>
        <td>{fileName || '--'}</td>
        <td>{reportType || '--'} </td>
        <td>{monthYr ? moment(monthYr).format('MMM-YYYY') : '--'} </td>
        <td> {fullName || '--'} </td>
        <td>{formatDateUSWithMoment(createdAt)}</td>
        <td><DownloadButton nickName={fileName} url={fileLink!} /></td>
      </>
    )
  }

  const listTempDataImportsCallback = useCallback(async () => {
    if (filtering && columnFilters?.monthYrSearch) {
      window.clearTimeout(time);
      time = window.setTimeout(async () => {
        await listTempDataImports({
          variables: {
            payload: {
              limit,
              page: currentPage,
            },
            columnFilters: { ...columnFilters, monthYrSearch: moment(columnFilters.monthYrSearch).format("YYYY-MM") }
          },
        });
      }, 1000);
    } else {
      await listTempDataImports({
        variables: {
          payload: {
            limit,
            page: currentPage,
          }
        },
      });
    }
  }, [currentPage, limit, columnFilters]);

  useEffect(() => {
    listTempDataImportsCallback()
  }, [listTempDataImportsCallback])

  return <>
    <div className="d-flex m- my-4" >
      <h5>{TEMP_BILLING_IMPORT}</h5>
      <CustomButton className="ml-auto" buttonText="Add"
        buttonClick={() => setIsOpen(!isOpen)}
      />
    </div>

    <div className="table-responsive placement-table">
      <TableComponent
        advanceSearching={true}
        handleChange={changeFiled}
        register={register}
        setValue={setValue}
        tableHeaderAdvSeacrh={TEMP_BILLING_IMPORTS_HEADER}>
        <tbody>
          {listLoading && (
            <td colSpan={14}>
              <Loader loaderClass="sm-height " />
            </td>
          )}
          {!listLoading && !listData?.dataImportTempBillings?.dataImports?.length && (
            <td colSpan={14} className="empty-table-td">
              <NoDataFound text="No Record Found!" />
            </td>
          )}
          {!listLoading && listData?.dataImportTempBillings?.dataImports?.map((item: ITempBillingImportsData, index: number) => {
            return <tr key={index}>{mapCSVListData(item)}</tr>
          })}
        </tbody>
      </TableComponent>
    </div >

    {!listLoading && !!listData?.dataImportTempBillings?.dataImports?.length &&
      <div className="users-pagination py-3" >
        <Pagination
          onPageChange={(pageClicked: number) => {
            setCurrentPage(pageClicked);
          }} currentPage={currentPage}
          pageCount={Math.ceil(listData?.dataImportTempBillings?.count / limit)}
        />
        <div className="d-flex align-items-center">
          <div className="pagination-display paginationItemsSelect">
            <p className="m-0">
              {(currentPage - 1) * limit + 1} to{' '}
              {Math.min(
                currentPage * limit,
                listData?.dataImportTempBillings?.count
              )}
              {` of `} {listData?.dataImportTempBillings?.count}
            </p>

            <div className="divider"></div>
            <label>Display</label>
            <InputField
              label=""
              inputtype="select"
              selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
              inputid="limit"
              placeholder={limit.toString()}
              inputMethod={(value) => {
                setCurrentPage(1);
                setLimit(parseInt(value));
              }}
            />
          </div>
        </div>
      </ div>}
    <CreateTempBillingReportModal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} refetch={refetch} />
  </>

}

export default AllTempBillingReports