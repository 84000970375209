import { WeekHours } from "../interfaces";

export const SEARCH_GENERIC = 'Search by Job Title...';
export const CANDIDATE_SEARCH = 'Candidate Search';
export const FILTERS = 'Filters';
export const CITY = 'City';
export const CLEAR = 'Clear';
export const STATE = 'State';
export const COUNTRY = 'Country';
export const ZIP = 'Zip';
export const AREA_WITHIN_RADIUS = 'Area within (radius)';
export const SELECT_All = 'Select All';
export const RECORDS_SELECTED = 'records selected';
export const CLEAR_SELECTION = 'Clear Selection';
export const MOVE_TO_FOLDER = 'Move to folder';
export const ADD_TO_TJ_LOOKUP = 'Add to TJ Lookup';
export const SELECT_DESTINATION_FOLDER = 'Select a destination folder';
export const TRAINING_VIDEOS = "Training Videos";
export const GOAL_SHEET_REPORT = "Goal Sheet"
export const RECRUITER_DETAIL = "Recruiter Detail"
export const USER_ACTIVITY_REPORT = "User Activity Report"
export const TEMP_BILLING_IMPORT = "Temp Billing Imports"
export const EMAIL_TEMPLATES = "Email Templates"
export const RECRUITER_DEAL_TEMPLATES = "Recruiter Deal Templates"
export const TJ_LOOKUP_SETTING = "TJ Lookup Setting";
export const IMPORT_CSV = "Import CSV"
export const COMPANY_OWNERSHIP_REPORT = "Company Ownership Report";
export const CANDIDATE_OWNERSHIP_REPORT = "Candidate Ownership Report";
export const ADD_NEW_VIDEO = "Add New Video";
export const SEARCH = "Search";
export const SCHEDULE = "Schedule";
export const EDITING_SCHEDULE = "Editing Schedule";
export const CLICK_THE_RANGE = "Click the range above to create a new time block";
export const CLEAR_THE_RANGE = "Clear time range";
export const EDITING_SCHEDULE_DESC = "Select the time ranges to send messages in your sequence";
export const SAVE_TIME_RANGE_DESC = "Save time range for additional days";
export const SCHEDULE_DESC = "Set when your emails get sent out";
export const VOLUME = "Volume";
export const SAVE = "Save";
export const ADD = "Add";
export const DELETE = "Delete";
export const CANCEL = "Cancel";
export const TIMEZONE = "Timezone";
export const CHATGPT = "ChatGPT";
export const SYNONYM_LIST = "Synonym List";
export const MODULE_ACCESS = "Module Access";
export const CANDIDATE_SEARCH_DEV = "Candidate Search (Dev)";
export const CHATGPT_TEMPLATES = "ChatGPT Templates";
export const RESUME_PARSING = "Resume Parsing";
export const CSV_IMPORT = "CSV Import";
export const MODULE = "Module";
export const ACCESS = "Access";
export const USERS = "Users";
export const TOTAL = "Total";
export const DEPLOY = "Deploy";
export const TIME_TO_FILL = "Time To Fill";
export const JOB_LIST = "Job List";
export const ADD_RECORD = "Add Record";
export const EDIT_RECORD = "Edit Record";
export const UPDATE_RECORD = "Update Record";
export const SYNONYMS = "Synonyms";
export const ADD_SYNONYM = "Add Synonym";
export const EDIT_SYNONYMS = "Edit Synonyms";
export const ADD_SYNONYMS = "Add Synonyms";
export const TIMEZONE_DESC = "Set the default timezone for your schedule";
export const VOLUME_DESC = "Set the frequency of emails to be sent during the schedule";
export const MAX_EMAIL_DESC = "Maximum emails to send per day";
export const SECONDS_DESC = "Seconds to wait between each email";
export const EXCLUDE_US_HOLIDAYS = "EXCLUDE U.S. HOLIDAYS";
export const PREVENT_MESSAGE = "Prevent messages from being sent during U.S. public holidays";
export const CUSTOM_HOLIDAYS = "CUSTOM HOLIDAYS";
export const SELECT_DATE = "Select a Date";
export const CUSTOM_HOLIDAYS_DESC = "Choose your own custom dates to prevent messages from being sent out";
export const APPROVED = 'Approved';
export const REQUESTS = 'Requests';
export const NOTES = 'Notes';
export const CLOSE = 'Close';
export const EXPECTATION_TARGETS = 'Expectation Targets:'
export const START_DATE = 'Start Date'
export const PERIOD_END_DATE = 'Period End Date'
export const WEEKS = 'Weeks'
export const DAYS = 'Days'
export const EARNING_GOAL = 'Earning Goal'
export const BILLING_SUMMARY = 'Billing Summary:'
export const WHAT_TO_EXPECT_IF = 'What to expect:'
export const WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const DAY_NUMBERS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const US_HOLIDAYS_DATA = [
  'Memorial Day — Monday, May 29, 2023', 'Juneteenth National Independence Day — Monday, June 19, 2023', 'Independence Day — Tuesday, July 4, 2023',
  'Labor Day — Monday, September 4, 2023', 'Columbus Day — Monday, October 9, 2023', 'Veterans Day — Saturday, November 11, 2023',
  'Thanksgiving Day — Thursday, November 23, 2023', 'Christmas Day — Monday, December 25, 2023'
]

export const recruiterDealTemplatesQuillModules = {
  toolbar: [
    [{ font: [] }],
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ blockquote: true }, { code: true }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

export const recruiterDealTemplatesQuillFormats = ["font", "size", "bold", "italic", "underline", "strike", "color", "background", "script", "header", "blockquote", "code", "list", "bullet", "indent", "align", "link", "image", "video",];

export const WEEK_HOURS: WeekHours[] = [
  {
    id: 1,
    day: 'SUNDAY',
    hours: [
      {
        hr: '12:00AM - 12:59AM',
        value: 1
      },
      {
        hr: '1:00AM - 1:59AM',
        value: 2
      },
      {
        hr: '2:00AM - 2:59AM',
        value: 3
      },
      {
        hr: '3:00AM - 3:59AM',
        value: 4
      },
      {
        hr: '4:00AM - 4:59AM',
        value: 5
      },
      {
        hr: '5:00AM - 5:59AM',
        value: 6
      },
      {
        hr: '6:00AM - 6:59AM',
        value: 7
      },
      {
        hr: '7:00AM - 7:59AM',
        value: 8
      },
      {
        hr: '8:00AM - 8:59AM',
        value: 9
      },
      {
        hr: '9:00AM - 9:59AM',
        value: 10
      },
      {
        hr: '10:00AM - 10:59AM',
        value: 11
      },
      {
        hr: '11:00AM - 11:59AM',
        value: 12
      },
      {
        hr: '12:00PM - 12:59PM',
        value: 13
      },
      {
        hr: '1:00PM - 1:59PM',
        value: 14
      },
      {
        hr: '2:00PM - 2:59PM',
        value: 15
      },
      {
        hr: '3:00PM - 3:59PM',
        value: 16
      },
      {
        hr: '4:00PM - 4:59PM',
        value: 17
      },
      {
        hr: '5:00PM - 5:59PM',
        value: 18
      },
      {
        hr: '6:00PM - 6:59PM',
        value: 19
      },
      {
        hr: '7:00PM - 7:59PM',
        value: 20
      },
      {
        hr: '8:00PM - 8:59PM',
        value: 21
      },
      {
        hr: '9:00PM - 9:59PM',
        value: 22
      },
      {
        hr: '10:00PM - 10:59PM',
        value: 23
      },
      {
        hr: '11:00PM - 11:59PM',
        value: 24
      },
    ]
  },
  {
    id: 2,
    day: 'MONDAY',
    hours: [
      {
        hr: '12:00AM - 12:59AM',
        value: 25
      },
      {
        hr: '1:00AM - 1:59AM',
        value: 26
      },
      {
        hr: '2:00AM - 2:59AM',
        value: 27
      },
      {
        hr: '3:00AM - 3:59AM',
        value: 28
      },
      {
        hr: '4:00AM - 4:59AM',
        value: 29
      },
      {
        hr: '5:00AM - 5:59AM',
        value: 30
      },
      {
        hr: '6:00AM - 6:59AM',
        value: 31
      },
      {
        hr: '7:00AM - 7:59AM',
        value: 32
      },
      {
        hr: '8:00AM - 8:59AM',
        value: 33
      },
      {
        hr: '9:00AM - 9:59AM',
        value: 34
      },
      {
        hr: '10:00AM - 10:59AM',
        value: 35
      },
      {
        hr: '11:00AM - 11:59AM',
        value: 36
      },
      {
        hr: '12:00PM - 12:59PM',
        value: 37
      },
      {
        hr: '1:00PM - 1:59PM',
        value: 38
      },
      {
        hr: '2:00PM - 2:59PM',
        value: 39
      },
      {
        hr: '3:00PM - 3:59PM',
        value: 40
      },
      {
        hr: '4:00PM - 4:59PM',
        value: 41
      },
      {
        hr: '5:00PM - 5:59PM',
        value: 42
      },
      {
        hr: '6:00PM - 6:59PM',
        value: 43
      },
      {
        hr: '7:00PM - 7:59PM',
        value: 44
      },
      {
        hr: '8:00PM - 8:59PM',
        value: 45
      },
      {
        hr: '9:00PM - 9:59PM',
        value: 46
      },
      {
        hr: '10:00PM - 10:59PM',
        value: 47
      },
      {
        hr: '11:00PM - 11:59PM',
        value: 48
      },
    ]
  },
  {
    id: 3,
    day: 'TUESDAY',
    hours: [
      {
        hr: '12:00AM - 12:59AM',
        value: 49
      },
      {
        hr: '1:00AM - 1:59AM',
        value: 50
      },
      {
        hr: '2:00AM - 2:59AM',
        value: 51
      },
      {
        hr: '3:00AM - 3:59AM',
        value: 52
      },
      {
        hr: '4:00AM - 4:59AM',
        value: 53
      },
      {
        hr: '5:00AM - 5:59AM',
        value: 54
      },
      {
        hr: '6:00AM - 6:59AM',
        value: 55
      },
      {
        hr: '7:00AM - 7:59AM',
        value: 56
      },
      {
        hr: '8:00AM - 8:59AM',
        value: 57
      },
      {
        hr: '9:00AM - 9:59AM',
        value: 58
      },
      {
        hr: '10:00AM - 10:59AM',
        value: 59
      },
      {
        hr: '11:00AM - 11:59AM',
        value: 60
      },
      {
        hr: '12:00PM - 12:59PM',
        value: 61
      },
      {
        hr: '1:00PM - 1:59PM',
        value: 62
      },
      {
        hr: '2:00PM - 2:59PM',
        value: 63
      },
      {
        hr: '3:00PM - 3:59PM',
        value: 64
      },
      {
        hr: '4:00PM - 4:59PM',
        value: 65
      },
      {
        hr: '5:00PM - 5:59PM',
        value: 66
      },
      {
        hr: '6:00PM - 6:59PM',
        value: 67
      },
      {
        hr: '7:00PM - 7:59PM',
        value: 68
      },
      {
        hr: '8:00PM - 8:59PM',
        value: 69
      },
      {
        hr: '9:00PM - 9:59PM',
        value: 70
      },
      {
        hr: '10:00PM - 10:59PM',
        value: 71
      },
      {
        hr: '11:00PM - 11:59PM',
        value: 72
      },
    ]
  },
  {
    id: 4,
    day: 'WEDNESDAY',
    hours: [
      {
        hr: '12:00AM - 12:59AM',
        value: 73
      },
      {
        hr: '1:00AM - 1:59AM',
        value: 74
      },
      {
        hr: '2:00AM - 2:59AM',
        value: 75
      },
      {
        hr: '3:00AM - 3:59AM',
        value: 76
      },
      {
        hr: '4:00AM - 4:59AM',
        value: 77
      },
      {
        hr: '5:00AM - 5:59AM',
        value: 78
      },
      {
        hr: '6:00AM - 6:59AM',
        value: 79
      },
      {
        hr: '7:00AM - 7:59AM',
        value: 80
      },
      {
        hr: '8:00AM - 8:59AM',
        value: 81
      },
      {
        hr: '9:00AM - 9:59AM',
        value: 82
      },
      {
        hr: '10:00AM - 10:59AM',
        value: 83
      },
      {
        hr: '11:00AM - 11:59AM',
        value: 84
      },
      {
        hr: '12:00PM - 12:59PM',
        value: 85
      },
      {
        hr: '1:00PM - 1:59PM',
        value: 86
      },
      {
        hr: '2:00PM - 2:59PM',
        value: 87
      },
      {
        hr: '3:00PM - 3:59PM',
        value: 88
      },
      {
        hr: '4:00PM - 4:59PM',
        value: 89
      },
      {
        hr: '5:00PM - 5:59PM',
        value: 90
      },
      {
        hr: '6:00PM - 6:59PM',
        value: 91
      },
      {
        hr: '7:00PM - 7:59PM',
        value: 92
      },
      {
        hr: '8:00PM - 8:59PM',
        value: 93
      },
      {
        hr: '9:00PM - 9:59PM',
        value: 94
      },
      {
        hr: '10:00PM - 10:59PM',
        value: 95
      },
      {
        hr: '11:00PM - 11:59PM',
        value: 96
      },
    ]
  },
  {
    id: 5,
    day: 'THURSDAY',
    hours: [
      {
        hr: '12:00AM - 12:59AM',
        value: 97
      },
      {
        hr: '1:00AM - 1:59AM',
        value: 98
      },
      {
        hr: '2:00AM - 2:59AM',
        value: 99
      },
      {
        hr: '3:00AM - 3:59AM',
        value: 100
      },
      {
        hr: '4:00AM - 4:59AM',
        value: 101
      },
      {
        hr: '5:00AM - 5:59AM',
        value: 102
      },
      {
        hr: '6:00AM - 6:59AM',
        value: 103
      },
      {
        hr: '7:00AM - 7:59AM',
        value: 104
      },
      {
        hr: '8:00AM - 8:59AM',
        value: 105
      },
      {
        hr: '9:00AM - 9:59AM',
        value: 106
      },
      {
        hr: '10:00AM - 10:59AM',
        value: 107
      },
      {
        hr: '11:00AM - 11:59AM',
        value: 108
      },
      {
        hr: '12:00PM - 12:59PM',
        value: 109
      },
      {
        hr: '1:00PM - 1:59PM',
        value: 110
      },
      {
        hr: '2:00PM - 2:59PM',
        value: 111
      },
      {
        hr: '3:00PM - 3:59PM',
        value: 112
      },
      {
        hr: '4:00PM - 4:59PM',
        value: 113
      },
      {
        hr: '5:00PM - 5:59PM',
        value: 114
      },
      {
        hr: '6:00PM - 6:59PM',
        value: 115
      },
      {
        hr: '7:00PM - 7:59PM',
        value: 116
      },
      {
        hr: '8:00PM - 8:59PM',
        value: 117
      },
      {
        hr: '9:00PM - 9:59PM',
        value: 118
      },
      {
        hr: '10:00PM - 10:59PM',
        value: 119
      },
      {
        hr: '11:00PM - 11:59PM',
        value: 120
      },
    ]
  },
  {
    id: 6,
    day: 'FRIDAY',
    hours: [
      {
        hr: '12:00AM - 12:59AM',
        value: 121
      },
      {
        hr: '1:00AM - 1:59AM',
        value: 122
      },
      {
        hr: '2:00AM - 2:59AM',
        value: 123
      },
      {
        hr: '3:00AM - 3:59AM',
        value: 124
      },
      {
        hr: '4:00AM - 4:59AM',
        value: 125
      },
      {
        hr: '5:00AM - 5:59AM',
        value: 126
      },
      {
        hr: '6:00AM - 6:59AM',
        value: 127
      },
      {
        hr: '7:00AM - 7:59AM',
        value: 128
      },
      {
        hr: '8:00AM - 8:59AM',
        value: 129
      },
      {
        hr: '9:00AM - 9:59AM',
        value: 130
      },
      {
        hr: '10:00AM - 10:59AM',
        value: 131
      },
      {
        hr: '11:00AM - 11:59AM',
        value: 132
      },
      {
        hr: '12:00PM - 12:59PM',
        value: 133
      },
      {
        hr: '1:00PM - 1:59PM',
        value: 134
      },
      {
        hr: '2:00PM - 2:59PM',
        value: 135
      },
      {
        hr: '3:00PM - 3:59PM',
        value: 136
      },
      {
        hr: '4:00PM - 4:59PM',
        value: 137
      },
      {
        hr: '5:00PM - 5:59PM',
        value: 138
      },
      {
        hr: '6:00PM - 6:59PM',
        value: 139
      },
      {
        hr: '7:00PM - 7:59PM',
        value: 140
      },
      {
        hr: '8:00PM - 8:59PM',
        value: 141
      },
      {
        hr: '9:00PM - 9:59PM',
        value: 142
      },
      {
        hr: '10:00PM - 10:59PM',
        value: 143
      },
      {
        hr: '11:00PM - 11:59PM',
        value: 144
      },
    ]
  },
  {
    id: 7,
    day: 'SATURDAY',
    hours: [
      {
        hr: '12:00AM - 12:59AM',
        value: 145
      },
      {
        hr: '1:00AM - 1:59AM',
        value: 146
      },
      {
        hr: '2:00AM - 2:59AM',
        value: 147
      },
      {
        hr: '3:00AM - 3:59AM',
        value: 148
      },
      {
        hr: '4:00AM - 4:59AM',
        value: 149
      },
      {
        hr: '5:00AM - 5:59AM',
        value: 150
      },
      {
        hr: '6:00AM - 6:59AM',
        value: 151
      },
      {
        hr: '7:00AM - 7:59AM',
        value: 152
      },
      {
        hr: '8:00AM - 8:59AM',
        value: 153
      },
      {
        hr: '9:00AM - 9:59AM',
        value: 154
      },
      {
        hr: '10:00AM - 10:59AM',
        value: 155
      },
      {
        hr: '11:00AM - 11:59AM',
        value: 156
      },
      {
        hr: '12:00PM - 12:59PM',
        value: 157
      },
      {
        hr: '1:00PM - 1:59PM',
        value: 158
      },
      {
        hr: '2:00PM - 2:59PM',
        value: 159
      },
      {
        hr: '3:00PM - 3:59PM',
        value: 160
      },
      {
        hr: '4:00PM - 4:59PM',
        value: 161
      },
      {
        hr: '5:00PM - 5:59PM',
        value: 162
      },
      {
        hr: '6:00PM - 6:59PM',
        value: 163
      },
      {
        hr: '7:00PM - 7:59PM',
        value: 164
      },
      {
        hr: '8:00PM - 8:59PM',
        value: 165
      },
      {
        hr: '9:00PM - 9:59PM',
        value: 166
      },
      {
        hr: '10:00PM - 10:59PM',
        value: 167
      },
      {
        hr: '11:00PM - 11:59PM',
        value: 168
      },
    ]
  },
]

export const DAY_HOUR_IDS = [
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
  [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48],
  [49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72],
  [73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96],
  [97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120],
  [121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144],
  [145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168]
]

export const RADIUS_OPTIONS = (country) => {
  return [
    {
      value: '5',
      label: `5 ${['US', null, undefined, ''].includes(country) ? "Miles" : "KM"}`,
    },
    {
      value: '10',
      label: `10 ${['US', null, undefined, ''].includes(country) ? "Miles" : "KM"}`,
    },
    {
      value: '25',
      label: `25 ${['US', null, undefined, ''].includes(country) ? "Miles" : "KM"}`,
    },
    {
      value: '50',
      label: `50 ${['US', null, undefined, ''].includes(country) ? "Miles" : "KM"}`,
    },
    {
      value: '75',
      label: `75 ${['US', null, undefined, ''].includes(country) ? "Miles" : "KM"}`,
    },
    {
      value: '100',
      label: `100 ${['US', null, undefined, ''].includes(country) ? "Miles" : "KM"}`,
    },
  ];
}

export const EMPLOYMENT_HEADER = [
  'NOTE CATEGORY',
  'NOTE',
  'CREATED BY',
  'MODIFIED',
  'ACTIONS',
];

export const RECRUITER_DEALS_HEADER = (povRecruiter: boolean) => ['DEAL NAME', 'TITLE', 'DH/Temp Base Rate', povRecruiter ? 'START DATE' : 'CREATED ON', 'UPDATED ON',
  'STATUS', 'ACTIONS']

export const MENTORS_COMMISSION_HEADER = ['FULL NAME', 'TITLE', 'START DATE', 'END DATE', 'Team Lead %']

export const JOB_LIST_NOTES_HEADER = [
  'SALARY NOTE',
  'URGENCY NOTE',
  'PIPELINE NOTE',
  'CREATED AT',
  'ACTIONS',
];

export const ACTIVE_WIDGETS_HEADER = [
  '',
  'Name',
  'Job Title',
  'Email',
  'Location',
  'Source',
  'Resume updated',
  'Status',
  'Actions',
];

export const TJ_TALK__VIDEO_HEADER = [
  'TITLE',
  'LINK',
  'ACTIONS',
];

export const REPORTS_HEADER = [
  'NAME',
  'LINK',
  'CATEGORY',
  'ACTIONS',
]

export const ACTIVITY_REPORTS_HEADER = (recruiterDetailPage?: boolean) => {

  if (!recruiterDetailPage) {
    return [
      { userSearch: 'username' },
      { monthSearch: 'month' },
      { yearSearch: 'year' },
      { actions: 'Actions' }]
  }
  return [{ Month: 'Month' }, { yearSearch: 'Year' }, { actions: 'Actions' }]
}

export const REALREPPDOCUMENT_HEADER = [
  'NAME',
  'DOWNLOADS',
  'ACTIONS',
]

export const FOLDER_MOVE_HEADER = [
  'FOLDER NAME',
  'TOTAL COUNT IN FOLDER',
  'ACTIONS',
];
export const USER_TABLE_HEADER = [
  'FULL NAME',
  'EMAIL',
  'PHONE NO.',
  'ROLE',
  'STATUS',
  'ACTIONS',
];

export const AGREEMENT_HEADERS = [
  'Company',
  'Contact',
  'Nickname',
  'Type',
  'Commission',
  'Account Manager',
  'Status',
  'Document',
  'Action',
];

export const AGREEMENT_HISTORY_HEADERS = [
  'Reviewer',
  'Old State',
  'New State',
  'Created At',
  'Last Updated',
  'Notes',
];

export const REFERENCE_HEADER = [
  'Reference Name',
  'Reference Email',
  'Phone',
  'Status',
  'Updated At',
  'ACTIONS',
];

export const COMPANY_CONTACT_NOTES_HEADER = [
  'NOTE CATEGORY',
  'NOTE',
  'CONTACT',
  'CREATED BY',
  'MODIFIED',
  'ACTIONS',
];

export const COMPANY_HEADER_HEADER = [
  'COMPANY',
  'Active Jobs',
  'Location',
  'COMPANY SIZE',
  'Last Update',
  'STATUS',
  'Agreements',
  'Actions',
];



// --------------------------- ENUMS ---------------------------------------

export enum UserStatus {
  NEW_HIRE = 'New Hire',
  ACTIVE = 'Active',
  ON_LEAVE = 'On Leave',
  SUSPENDED = 'Suspended',
  TERMINATED = 'Terminated',
}

export enum ReportEnum {
  TEAM_REPORT = "Team Report",
  RECRUITER_REPORT = "Recruiter Report",
  COMPANY_REPORT = "Company Report",
  EXECUTIVE_AND_HR_REPORT = "Executive & HR Report"
}

export enum RealReppDocumentEnum {
  HR_DOCUMENTS = 'HR Documents',
  TRAINING_DOCUMENTS = 'Training Documents',
  BENEFIT_DOCUMENTS = "Benefit Documents"
}

export enum HotJobStatus {
  WARM = 'WARM',
  HOLD = 'HOLD',
  REFERENCE = 'REFERENCE',
  NONE = 'NONE',
}

export enum RequestsStatusEnum {
  PENDING = 'Pending',
  APPROVED = 'Approved',
  DECLINED = 'Declined',
}

export enum RequestsActionableType {
  COMPANY = 'Company',
  PLACEMENT = 'Placement'
}

export enum RequestsStatusEnumForColor {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Declined = 'DECLINED',
}

export enum RequestsTypeEnum {
  PLACEMENT_CANCELLATION = 'Placement Cancellation',
  COMPANY_OWNERSHIP = 'Company Ownership',
}

export enum TierType {
  DIRECT_HIRE = "Direct Hire",
  CONTRACT = "Contract",
}
// ---------------------------- STYLES -------------------------------------

export const CUSTOM_SELECT_STYLE = {
  control: (styles, provided) => ({
    ...styles,
    fontSize: 12,
    minHeight: '45px',
    outline: 0,
  }),
  menu: (provided) => ({
    ...provided,
    border: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: 12,
  }),
  indicatorSeparator: () => ({ display: 'none' }),

  container: (provided, state) => ({
    ...provided,
    fontSize: 12,
  }),
};
