import { gql } from "@apollo/client";

export const LISTING_TEMP_DATA_IMPORTS = gql`
query($payload: PageCountInput!,$columnFilters:TempBillingImportsColumnFilter) {
  dataImportTempBillings(payload: $payload,columnFilters:$columnFilters) {
    dataImports {
      id
      fileName
      fileLink
      reportType
      monthYr
      createdAt
      uploadedBy {
        fullName
      }
    }
    count
    message
    status
  }
}`

export const CREATE_TEMP_BILLING_IMPORT = gql`
mutation($input: CreateDataImportTempBillingInput!) {
  createDataImportTempBilling(createDataImportTempBillingInput: $input) {
    message
    status
  }
}`